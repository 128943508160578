var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "app-calendar p-2"
  }, [_c('FullCalendar', {
    staticClass: "full-calendar",
    attrs: {
      "options": _vm.calendarOptions
    },
    scopedSlots: _vm._u([{
      key: "eventContent",
      fn: function fn(arg) {
        var _arg$event, _arg$event$extendedPr, _arg$event2, _arg$event2$extendedP, _arg$event2$extendedP2;
        return [arg !== null && arg !== void 0 && (_arg$event = arg.event) !== null && _arg$event !== void 0 && (_arg$event$extendedPr = _arg$event.extendedProps) !== null && _arg$event$extendedPr !== void 0 && _arg$event$extendedPr.weekends ? [_c('div', {
          staticClass: "d-flex flex-column justify-content-center align-items-center text-white w-100 h-100 p-1",
          attrs: {
            "role": "button"
          }
        }, [_c('span', {
          staticClass: "text-wrap text-truncate"
        }, [_vm._v(" " + _vm._s(arg === null || arg === void 0 ? void 0 : (_arg$event2 = arg.event) === null || _arg$event2 === void 0 ? void 0 : (_arg$event2$extendedP = _arg$event2.extendedProps) === null || _arg$event2$extendedP === void 0 ? void 0 : (_arg$event2$extendedP2 = _arg$event2$extendedP.weekends) === null || _arg$event2$extendedP2 === void 0 ? void 0 : _arg$event2$extendedP2.description) + " ")])])] : _vm._e()];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }